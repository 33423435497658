import React, {useState} from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './connect';
import { BrowserRouter as Router, Route } from "react-router-dom";

import ReplicatedSite from './pages/replicatedSite';
import LandingPage from './pages/landingPage';
import GetStarted from './pages/getStarted';
import Results from './components/results';
import { AppContext } from './utilities/appContext'; 
import ScrollToTop from './components/scrollToTop'

const App = () => {

  const [age, setAge ] = useState(
    sessionStorage.getItem('age') || ''
  );
  const [income, setIncome] = useState(
    sessionStorage.getItem('income') || ''
  );
  const [savings, setSavings] = useState(
    sessionStorage.getItem('savings') || ''
  );
  const [contribution, setContribution] = useState(
    sessionStorage.getItem('contribution') || ''
  );
  const [costOfLiving, setCostOfLiving] = useState(
    sessionStorage.getItem('costOfLiving') || 1
  );
  const [futureValue, setFutureValue] = useState(0);
  const [standardOfLiving, setStandardOfLiving] = useState('');
  const [lifeExpectancy, setLifeExpectancy] = useState(87);
  const [retirementAge, setRetirementAge] = useState(67);
  const [email, setEmail] = useState('');
  const [validOptIn, setValidOptIn] = useState(false);
  const [socialSecurity, setSocialSecurity] = useState(0);
  const [maxWithdrawl, setMaxWithdrawl] = useState(0);
  const [whatYoullHave, setWhatYoullHave] = useState(0);
  const [whatYoullNeed, setWhatYoullNeed] = useState(0);
  const [investmentStyle, setInvestmentStyle] = useState(5.5);
  const [blueNumber, setBlueNumber] = useState(0);
  const [referral, setReferral] = useState({
    distId: sessionStorage.getItem('distId') || '',
    siteUrl: '',
    assocName: '',
    assocEmail: '',
    assocPhone: '',
    validUrl: ''
  });
  const [seenResults, setSeenResults] = useState(
    sessionStorage.getItem('seenResults' || false)
  );


  const value = {
    age, setAge, 
    income, setIncome,
    savings, setSavings,
    futureValue, setFutureValue, 
    contribution, setContribution,
    costOfLiving, setCostOfLiving,
    standardOfLiving, setStandardOfLiving,
    lifeExpectancy, setLifeExpectancy,
    retirementAge, setRetirementAge,
    email, setEmail,
    validOptIn, setValidOptIn,
    socialSecurity, setSocialSecurity,
    maxWithdrawl, setMaxWithdrawl,
    whatYoullHave, setWhatYoullHave,
    whatYoullNeed, setWhatYoullNeed,
    investmentStyle, setInvestmentStyle,
    blueNumber, setBlueNumber,
    referral, setReferral,
    seenResults, setSeenResults,
  }


  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <AppContext.Provider value={value}>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/:id" render={(props) => <ReplicatedSite {...props} />} />
          <Route exact path="/get-started" component={GetStarted} />
          <Route exact path="/results" component={Results} />
        </AppContext.Provider>
      </Router>
      <footer className="footer">
        <div className="footer__content">
        IMPORTANT: The numbers and information generated by the Tranont Life Blue Number are calculated with the data provided, investment outcomes and social security estimates are hypothetical in nature. The projected outcomes are not guaranteed estimates of present or future values or results. Results may vary.
        </div>
        <div className="footer__powered_by">
          <div>Powered by:<br /><img src="https://tranontwebsite.s3-us-west-2.amazonaws.com/icons/TranontLogo.png" alt="Tranont" /></div>

        </div>
      </footer>
    </ApolloProvider>
  )
};

export default App;