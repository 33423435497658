import React, { useState, useContext } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AppContext } from '../utilities/appContext';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles({
  root: {
    width: 265,
  },
  input: {
    width: 265,
    margin: "10px auto 0",
    fontSize: "18px",
    '& input': {
      fontSize: "18px",
      padding: "12px",
    },
  },
  listItem: {
    fontSize: "18px",
  }
});


const TranontSwitch = withStyles({
  switchBase: {
    color: '#0665BD',
    '&$checked': {
      color: '#0665BD',
    },
    '&$checked + $track': {
      backgroundColor: '#adc8e7',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ResultsCostOfLiving = () => {
  const classes = useStyles();
  const { costOfLiving, setCostOfLiving, standardOfLiving, setStandardOfLiving } = useContext(AppContext);
  const [quick, setQuick] = useState(false)

  const handleQuickChange = (e) => {
    setQuick(e.target.checked);
    setStandardOfLiving('');
  }

  const handleChange = (event) => {
    setCostOfLiving(event.target.value)
  }

  return(
    <div>
      <div className="col-label"><label>Estimated Need:</label></div>
      <div className="col-toggle">
        <span>Quick</span>
        <TranontSwitch
          checked={quick}
          onChange={handleQuickChange}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <span>Custom</span>
      </div>
      {quick ? 
        <div className={classes.root}>
          <TextField 
            value={standardOfLiving}
            variant="outlined" 
            onChange={(event) => setStandardOfLiving(event.target.value)}
            className={classes.input}
            autoFocus
            placeholder="Estimated Need (Monthly)"
          />
        </div>
        :
        <div className={classes.root}>
          <FormControl variant="outlined" className={classes.root}>
            <Select
              id="estimated need quick"
              value={costOfLiving}
              onChange={handleChange}
              className={classes.input}
            >
              <MenuItem className={classes.listItem} value={2}>I'll need less income</MenuItem>
              <MenuItem className={classes.listItem} value={1}>I'll need the same income</MenuItem>
              <MenuItem className={classes.listItem} value={3}>I'll need more income</MenuItem>
            </Select>
          </FormControl>
        </div>
      }
    </div>
  )
}

export default ResultsCostOfLiving;