import React from 'react'

const gridDots = () => {
  const n = 100;
  return (

    [...Array(n)].map((e, i) => (
      <svg key={i} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="8" height="8" rx="1" fill="#f0f0f0" fillOpacity="0.55"/>
      </svg>
  ))
  )
}

export const QuizBackground = () => {
  return (
    <div className="quiz__background">
        <img className="quiz__background--logo desktop" src="https://getmyblue-assets.s3-us-west-2.amazonaws.com/tranont-life-white.png" alt="logo" />
        <img className="quiz__background--logo mobile" src="https://tranontmedia.s3-us-west-2.amazonaws.com/Logos/tranontlife_logo.png" alt="logo" />
        <svg className="quiz__background--layer-1"  viewBox="0 0 885 894" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M88.6227 521.269C50.5735 462.016 -15.9453 424.633 -36.9623 357.71C-60.3096 283.366 -75.344 195.627 -33.8514 131.282C7.09139 67.7902 96.3708 65.061 167.883 40.0444C230.316 18.2041 289.848 -8.62294 356.463 -5.25782C431.258 -1.47949 503.646 18.7568 566.924 60.2023C644.575 111.062 730 167.297 755.558 256.142C782.194 348.735 764.978 459.284 702.049 529.93C641.969 597.377 527.329 562.035 446.619 602.963C362.57 645.584 321.206 791.594 227.994 770.323C133.453 748.749 141.349 603.38 88.6227 521.269Z" fill="#7AE8E1" fillOpacity="0.24"/>
        </svg>
        <svg className="quiz__background--layer-2" width="611" height="663" viewBox="0 0 611 663" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="-301.172" y="165.945" width="586.821" height="702.006" rx="146" transform="rotate(-45 -301.172 165.945)" fill="url(#paint0_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="-7.76132" y1="165.945" x2="-7.76132" y2="867.951" gradientUnits="userSpaceOnUse">
          <stop stopColor="#79BFFF"/>
          <stop offset="1" stopColor="#0665BD"/>
          </linearGradient>
          </defs>
        </svg>
        <svg className="quiz__background--layer-3" width="274" height="337" viewBox="0 0 274 337" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.0185547" y="252.916" width="63.6173" height="118.633" rx="10" transform="rotate(-45 0.0185547 252.916)" fill="#9AF4EE"/>
          <rect x="28.4189" y="149.537" width="63.6173" height="176.469" rx="10" transform="rotate(-45 28.4189 149.537)" fill="#61E2EA"/>
          <rect x="65.1113" y="45.9308" width="63.6173" height="230.613" rx="10" transform="rotate(-45 65.1113 45.9308)" fill="#01C4D0"/>
        </svg>
        <div className="quiz__background--layer-4">
          {gridDots()}        
        </div>
        {/* {for (let grid = 0; grid < 100; grid++) {
          return (
            <div>
              
            </div>
          )
        }
        } */}
      </div>
  )
}