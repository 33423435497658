import React, { useState } from 'react';

const Testimonials = () => {
  const [testimonial, setTestimonial] = useState(1)

  return (
    <div className="testimonial-container">
      <div>
      <div className="testimonial-widget" onClick={() => {setTestimonial(1)}}>
        <div className="testimonial-widget__initials">VS</div>
        <div className="testimonial-widget__info">
          <div className="testimonial-widget__info--name">
            Veronica S.
          </div>
          <div className="testimonial-widget__info--location">
            Nevada
          </div>
        </div>
      </div>
      <div className="testimonial-widget" onClick={() => {setTestimonial(2)}}>
        <div className="testimonial-widget__initials">AL</div>
        <div className="testimonial-widget__info">
          <div className="testimonial-widget__info--name">
            Alisha L.
          </div>
          <div className="testimonial-widget__info--location">
            Oregon
          </div>
        </div>
      </div>
      <div className="testimonial-widget" onClick={() => {setTestimonial(3)}}>
        <div className="testimonial-widget__initials">OB</div>
        <div className="testimonial-widget__info">
          <div className="testimonial-widget__info--name">
            Oksana B.
          </div>
          <div className="testimonial-widget__info--location">
            Washington
          </div>
        </div>
      </div>
      </div>
      {testimonial === 1 &&
        <div className="testimonial">
          "The Blue Number Analysis was an experience that gave me vision of what my financial future will be by applying simple principles today!"
        </div>
      }
      {testimonial === 2 &&
        <div className="testimonial">
          "It changed our lives and is leaving a legacy for our children!"
        </div>
      }
      {testimonial === 3 &&
        <div className="testimonial">
          "I finally have a future that I can count on being there for me."
        </div>
      }
    </div>
  )
}

export default Testimonials