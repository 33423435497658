import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../utilities/appContext';


const ResultsDescription = () => {
  const { blueNumber, whatYoullHave, whatYoullNeed } = useContext(AppContext);
  const [message, setMessage] = useState('')
  // const [total, setTotal] = useState('James was')
  let difference
  let findDifference = Number(whatYoullHave) - Number(whatYoullNeed)
  if (isNaN(findDifference)) {
    difference = 0
  } else {
    difference = findDifference
  }

  useEffect(() => scoreMessage())

  const scoreMessage = () => {
    if (blueNumber < 56 || isNaN(blueNumber)) {
      setMessage('Your retirement plan needs some work, but that’s okay! We have a solution for you, schedule your free consultation with a wealth specialist to find out how to reach your goals.')
    } else if (blueNumber < 72 && blueNumber > 55) {
      setMessage('You are headed in the right direction, but you’d benefit by meeting with a specialist to evaluate your plan! Your specialist will be able to help you create a solid plan to reach your goals. Schedule your free meeting to find out how to make your money work harder for you!')
    } else if (blueNumber < 90 && blueNumber > 71) {
      setMessage('You are on track! Schedule your free consultation with a specialist to find out how you can make your money work harder for you! You can learn how making adjustments can make drastic changes long term for you. ')
    } else if (blueNumber <= 100 && blueNumber > 89) {
      setMessage('Great work! You have a strong plan, keep it up and you should reach your goals. Schedule your free consultation with a specialist to see how you can further maximize your savings plan.')
    }
  }

  // scoreMessage()
  return (
    <div className="results__outcomes--description">
      <h4>What does this mean?</h4>
      <p>{message}</p>
      { (blueNumber < 56 || isNaN(blueNumber)) &&
        <p><strong>Tip: use the slide bar to see how increasing your monthly contribution improves your Blue Number.</strong></p>
      }
      <p>It's projected that you will need <span className="blue-text bold">${isNaN(whatYoullNeed) ? "0" : whatYoullNeed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> monthly to support your lifestyle, with your current savings and social security, you’ll have <span className="light-blue-text bold">${isNaN(whatYoullHave) ? "0" : whatYoullHave.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> of monthly income.  </p>
      {(difference <= -.01) && 
        <p>You will have a deficit of <span className="orange-text bold">${Number(difference).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> monthly. Schedule your free consultation today, to find out how to create your dream retirement!</p>
      }
    </div>
  )
}

export default ResultsDescription;