import React, { useContext } from 'react';
import { QuestionsContext } from '../utilities/questionsContext';
import { AppContext } from '../utilities/appContext';
import {QuizBackground} from '../components/quizBackground';

const Layout = (props) => {
  const { children } = props
  const {step, setStep} = useContext(QuestionsContext)
  const {age, income, savings, contribution, seenResults, setAge, setIncome, setSavings, setContribution, setCostOfLiving, setSeenResults } = useContext(AppContext)


  let valid 
  if (step === 1) {
    if (Number(age) > 17 && Number(age) < 99) {
      valid = true
    } else {
      valid = false 
    }
  } else if (step === 2) {
    if (income !== '') {
      valid = true
    } else {
      valid = false 
    }
  } else if (step === 3) {
    if (savings !== '') {
      valid = true
    } else {
      valid = false 
    }
  } else if (step === 4) {
    if (contribution !== '') {
      valid = true
    } else {
      valid = false 
    }
  } else if (step === 5) {
    valid = true
  }

  const handleReset = () => {
    sessionStorage.setItem('age', '')
    sessionStorage.setItem('income', '')
    sessionStorage.setItem('savings', '')
    sessionStorage.setItem('contribution', '')
    sessionStorage.setItem('costOfLiving', 1)
    sessionStorage.setItem('seenResults', false)
    setAge('');
    setIncome('');
    setSavings('');
    setContribution('');
    setCostOfLiving(1);
    setSeenResults(false)
  }

  const isValidHandler = () => {
    if (valid) {
      return(
        <div className="form-controls">
          {step > 1 && step < 6 ? <div onClick={() => setStep(step - 1)} className="back-btn">{ "< " } Back</div> : <>{seenResults ? <div onClick={handleReset} className="reset-btn">Reset fields</div> : <div></div>}</>}
          {step <= 5 && <button onClick={() => setStep(step + 1)} tabIndex="1" className="next-btn">Next</button>}
        </div>
      )
    } else {
      return(
        <div className="form-controls">
          {step > 1 && step < 6 ? <div onClick={() => setStep(step - 1)} className="back-btn">{ "< " } Back</div> : <div></div>}
          {step <= 5 && <button className="next-btn disabled">Next</button>}
        </div>
      )
    }
  }

  return(
    <div className="quiz">
      <QuizBackground />
      <div className="quiz__content">
        {step === 1 ? <p>Get your <span className="blue-text bold">Blue Number</span> in less than 1 minute by answering 5 simple questions.</p> : <p className="opacity-hidden">Get your <span className="bold">Blue Number</span> in less than 1 minute by answering 5 simple questions.</p>}
        <div className="form-container">
          <p className="step">{step} of 5 </p>
          {children}
        </div>
        {isValidHandler()}
      </div>
    </div>
  )
}

export default Layout;