import React, {useContext} from 'react';
import { AppContext } from '../utilities/appContext';

const ResultsAlerts = () => {
  const { maxWithdrawl, whatYoullNeed, age } = useContext(AppContext)

  let withdrawl
  let need
  let isYoung
  let alert

  if (Number(maxWithdrawl) === 0 || isNaN(maxWithdrawl)) {
    withdrawl = true
  } else {
    withdrawl = false
  }

  if (Number(whatYoullNeed) < 5000 ||isNaN(whatYoullNeed)) {
    need = true
  } else {
    need = false
  }

  if (Number(age) < 30) {
    isYoung = true
  } else {
    isYoung = false
  }

  if (withdrawl || need) {
    alert = true 
  } else {
    alert = false
  }

  if (alert) {
    return (
      <div className={alert && 'alert-message'} id="alert">
        <div className="alert-message__icon">!</div>
        {withdrawl &&
          <div>
            <h4>Monthly Contribution</h4>
            <p>According to your response, you aren’t contributing to a retirement account. This is an important part of your financial readiness – but don’t worry we are here to help you!</p>
            <p>Tip: Use the slider in your details to see how even a minimal monthly investment can drastically improve your Blue Number. By not saving money, your Blue Number is impacted 33%. The best time to start saving is now!</p>
          </div>
        }
        {need && 
          <div>
            <h4>Low Need</h4>
            <p>Your "Estimated Need" for retirement is below the national average retirement income. If you can maintain your current lifestyle, this may accurately reflect your needs. On the other hand, if you feel you'd like to enhance your retirement lifestyle, use the sliders under "My Details" to recalculate your income needs.</p>
            {isYoung && 
              <div>
                <h4>Age</h4>
                <p>This projection is based on your current picture – and we think your cost of living may increase as you begin to experience new life milestones. It might be that you’re just getting started in your career or your expenses may be lower than they might be in your 30s or 40s. Now is the time to get your money to work for you! Saving $100/month right now may get you an extra $500/month in your retirement income.</p>
                <p>Tip: Check out how your monthly savings can impact your income from investments on the slider in your details!</p>
              </div>
            }
          </div>
        }
      </div>
    )
  } else {
    return null
  }
}

export default ResultsAlerts;