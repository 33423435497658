import React, { useContext } from 'react';
import { CalculateResults } from './calculateResults';
import Slider from '@material-ui/core/Slider';
import { AppContext } from '../utilities/appContext';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ResultsCostOfLiving from './resultsCostOfLiving';
import { default as MaskInput} from 'react-number-format';

const useStyles = makeStyles({
  root: {
    width: 265,
  },
  input: {
    width: 265,
    fontSize: "18px",
    '& input': {
      fontSize: "18px",
      padding: "12px",
    },
  },
  listItem: {
    fontSize: "18px",
  }
});

const TranontSlider = withStyles({
  root: {
    color: '#0665BD',
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -4,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function valuetext(value) {
  return value;
}

const ResultsInputContainer = () => {
  const { age, setAge, income, setIncome, retirementAge, setRetirementAge, lifeExpectancy, setLifeExpectancy, contribution, setContribution, savings, setSavings, investmentStyle, setInvestmentStyle } = useContext(AppContext)
  const [value, setValue] = React.useState([age, retirementAge]);
  const classes = useStyles();


  const handleAgeChange = (event, newValue) => {
    setValue(newValue);
    setAge(value[0]);
    setRetirementAge(value[1]);
  }

  const handleInvestmentStyleChange = (event) => {
    setInvestmentStyle(event.target.value);
  }


  const handleIncomeChange = (event, newValue) => {
    setIncome(newValue);
  }

  const handleLifeExpectancyChange = (event, newValue) => {
    setLifeExpectancy(newValue);
  }

  const handleContributionChange = (event, newValue) => {
    setContribution(newValue);
  }

  const handleSavingsChange = (event, newValue) => {
    setSavings(newValue);
  }

  return (
    <>
      <CalculateResults />
      <h4 className="blue-text results-input-label">My Details</h4>
      <div className={classes.root}>
        <p>I am <MaskInput 
              value={age}
              className="results-inline-input"
              onValueChange={(values) => {
                const { value } = values
                setAge(value)
              }}
              style={{width: "45px"}}
            /> and plan to retire at 
            <MaskInput 
              value={retirementAge}
              className="results-inline-input"
              onValueChange={(values) => {
                const { value } = values
                setRetirementAge(value)
              }}
              style={{width: "35px"}}
            /> years of age.</p>
        <div>
          <TranontSlider 
            value={value}
            onChange={handleAgeChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            />
        </div>
      </div>
      <div className={classes.root}>
        <p>I plan to live to
          <MaskInput 
              thousandSeparator={true} 
              value={lifeExpectancy}
              className="results-inline-input"
              onValueChange={(values) => {
                const { value } = values
                setLifeExpectancy(value)
              }}
              style={{width: "35px"}}
            /> years of age.
        </p>
          <TranontSlider 
            value={lifeExpectancy}
            min={Number(retirementAge) + 1}
            max={120}
            step={1}
            onChange={handleLifeExpectancyChange}
            aria-labelledby="continuous-slider"
          />
      </div>
      <div className={classes.root}>
        <p>I make 
          <MaskInput 
            thousandSeparator={true} 
            prefix={'$'}
            value={income}
            hintText="$0.00"
            className="results-inline-input"
            style={{width: "100px"}}
            onValueChange={(values) => {
              const { value } = values
              setIncome(value)
            }}
          /> per year.</p>
        {/* <input value={income} onChange={(e) => setIncome(e.target.value)} style={{display: "inline"}} />  */}
          <TranontSlider 
            value={income}
            min={0}
            max={Number(income) + 50000}
            step={500}
            onChange={handleIncomeChange}
            aria-labelledby="continuous-slider"
          />
      </div>
      <div className={classes.root}>
        <p>I've already saved <MaskInput 
            thousandSeparator={true} 
            prefix={'$'}
            value={savings}
            hintText="$0.00"
            className="results-inline-input"
            style={{width: "100px"}}
            onValueChange={(values) => {
              const { value } = values
              setSavings(value)
            }}
          /> for retirement.</p>
          <TranontSlider 
            value={savings}
            min={0}
            max={Number(savings) + 10000}
            step={500}
            onChange={handleSavingsChange}
            aria-labelledby="continuous-slider"
          />
      </div>
      <div className={classes.root}>
        <p>I save <MaskInput 
            thousandSeparator={true} 
            prefix={'$'}
            value={contribution}
            hintText="$0.00"
            className="results-inline-input"
            style={{width: "75px"}}
            onValueChange={(values) => {
              const { value } = values
              setContribution(value)
            }}
          /> per month for retirement.</p>
          <TranontSlider 
            value={contribution}
            min={0}
            max={Number(contribution) + 5000}
            step={50}
            onChange={handleContributionChange}
            aria-labelledby="continuous-slider"
          />
      </div>
      <div className={classes.root}>
        <p>My investment style is:</p>
        <FormControl variant="outlined" className={classes.root}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={investmentStyle}
            onChange={handleInvestmentStyleChange}
            className={classes.input}
          >
            <MenuItem className={classes.listItem} value={0.0}>Short Term</MenuItem>
            <MenuItem className={classes.listItem} value={3.5}>Conservative</MenuItem>
            <MenuItem className={classes.listItem} value={5.5}>Balanced</MenuItem>
            <MenuItem className={classes.listItem} value={7}>Aggressive</MenuItem>
          </Select>
        </FormControl>
      </div>
      <ResultsCostOfLiving />
    </>
  )
}

export default ResultsInputContainer;