import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import LandingPage from './landingPage';
import { blackListUrls } from '../components/blackListUrls';
import { AppContext } from '../utilities/appContext';

const REPLICATED_SITE = gql`
  query repSite($siteUrl: String!) {
    repSite(siteUrl: $siteUrl) {
      distId
      siteUrl
      assocName
      assocEmail
      assocPhone
      validUrl
    }
  }
`

const ReplicatedSite = (props) => {
  const { loading, error, data } = useQuery(REPLICATED_SITE, {
    variables: { siteUrl: props.match.params.id }
  });

  const { referral, setReferral  } = useContext(AppContext);
  
  useEffect(() => {
    handleReferral()
  })

  const handleReferral = () => {
    if (loading) return null;
    if (error) return `${error}`;
    
    if (repSite.validUrl && !blackListUrls.includes(repSite.siteUrl)) {
      if (referral.validUrl === '') {

        setReferral({
          ...referral,
          distId: repSite.distId,
          siteUrl: repSite.siteUrl,
          assocName: repSite.assocName,
          assocEmail: repSite.assocEmail,
          assocPhone: repSite.assocPhone,
          validUrl: repSite.validUrl,
        })

        sessionStorage.setItem('distId', repSite.distId)
    }}
  }

  if (loading) return null;
  if (error) return `${error}`;
  
  const { repSite } = data
  if (repSite.validUrl && !blackListUrls.includes(repSite.siteUrl)) {
    return (<LandingPage referralSite={repSite} />)
  } else {
    return (null)
  }
}

export default ReplicatedSite;