import React, { useContext } from 'react';
import Widget from './widget';
import { Tag, Bill, Coin, Graph, Deficit, Surplus } from './icons';
import { AppContext } from '../utilities/appContext';

const ResultsWidgets = () => {
  const {whatYoullNeed, maxWithdrawl, socialSecurity, whatYoullHave} = useContext(AppContext);

  let difference
  let findDifference = Number(whatYoullHave) - Number(whatYoullNeed)
  if (isNaN(findDifference)) {
    difference = 0
  } else {
    difference = findDifference
  }

  let need 
  if (isNaN(whatYoullNeed)) {
    need = 0
  } else {
    need = whatYoullNeed
  }

  let investment 
  if (isNaN(maxWithdrawl)) {
    investment = 0 
  } else {
    investment = maxWithdrawl
  }

  let ss 
  if (isNaN(socialSecurity)) {
    ss = 0
  } else {
    ss = socialSecurity
  }

  let needPercentage 
  if (((need/10000) * 100) > 95) {
    needPercentage = 92
  } else {
    needPercentage = ((need/10000) * 100)
  }

  return (
    <div>
      <div className="results__section-header">
        <Graph />
        <p>Blue Number Breakdown by Month</p>
      </div> 
      <div className="results-widgets">
        <Widget color="blue" percent={needPercentage} attention={need < 5000}>              
          <Tag />
          <p className="small-label">Estimated Need</p>
          <p className="results-widgets__container--result blue-text">${need.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </Widget>
        {(difference <= 0) ?
          <Widget color="orange" percent={(((-1 * difference)/need) * 100)}>
            <Deficit />
            <p className="small-label">Estimated Deficit</p>
            <p className="results-widgets__container--result orange-text">${Number(difference).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
          </Widget>
          :
          <Widget color="light-blue" percent={((difference/need) * 100)}>
            <Surplus />
            <p className="small-label">Estimated Surplus</p>
            <p className="results-widgets__container--result light-blue-text">${Number(difference).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
          </Widget>
        }
        <Widget color="light-blue" percent={((investment/need) * 100)} attention={investment === 0}>              
          <Bill />
          <p className="small-label">Income from Investments</p>
          <p className="results-widgets__container--result light-blue-text">${investment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </Widget>
        <Widget color="light-blue" percent={((ss/need) * 100)}>
          <Coin />
          <p className="small-label">Estimated Social Security</p>
          <p className="results-widgets__container--result light-blue-text">${ss.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </Widget>
      </div>
    </div>
  )
}

export default ResultsWidgets;