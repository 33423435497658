import React, { useState } from 'react';
import Subscribe from '../components/subscribe';
import { CalculateResults } from '../components/calculateResults';
import Layout from '../components/layout';
import {
  DateOfBirth,
  Income,
  Savings, 
  Contribution,
} from '../components/questions';
import { QuestionsContext } from '../utilities/questionsContext';
import CostOfLiving from '../components/costOfLiving'

const GetStarted = () => {

  const [step, setStep] = useState(1)

  const value = {
    step, setStep
  }


  return (
    <QuestionsContext.Provider value={value}>
      {step === 1 && 
      <Layout>
        <DateOfBirth />
      </Layout>}
      {step === 2 && 
      <Layout>
        <Income />
      </Layout>}
      {step === 3 &&
      <Layout>
        <Savings />
      </Layout>}
      {step === 4 && 
      <Layout>
        <Contribution />
      </Layout>}
      {step === 5 && 
      <Layout>
       <CostOfLiving />
      </Layout>}
      <CalculateResults />
      {step === 6 && 
        <Subscribe />
      }
    </QuestionsContext.Provider>
  )
};

export default GetStarted;