import React, { useEffect, useState } from 'react';
import { Question } from '../components/icons'
import { default as MaskInput} from 'react-number-format';


export const Input = (props) => {
  const isMobile = window.innerWidth < 989
  const [help, setHelp] = useState(false)

  useEffect(() => {
    sessionStorage.setItem(`${props.name}`, props.value)
  }, [props.name, props.value])


  const handleClick = () => {
    if (help) {
      setHelp(false)
    } else {
      setHelp(true)
    }
  }

  return (
    <div className={`input-box ${props.inputClass}`}>
      <label>{props.label}</label>
      {help && 
        <div className="help-msg">
          {props.message}
        </div>
      }
      {props.number === "money" ?
        <MaskInput 
          thousandSeparator={props.number === "money" ? true : false} 
          value={props.value}
          onValueChange={(values) => {
            const { value } = values
            props.handleChange(value)
          }}
          tabIndex="1"
          autoFocus="autofocus"
          prefix={props.number === "money" && '$'}
          placeholder="$"
        />
        :
        <input 
          value={props.value}
          onChange={(e) => props.handleChange(e.target.value)}
          name={`${props.name}`}
          type={props.type}
          tabIndex="1"
          autoFocus="autofocus"
          placeholder={props.placeholder}
        />
      }
      <div className="inset-icon"
        onMouseEnter={() => setHelp(true)}
        onMouseLeave={() => setHelp(false)}
        onClick={isMobile && handleClick}
      >
        <Question active={help} />
      </div>
    </div>
  )
};

Input.defaultProps = {
  inputClass: 'prequiz',
  number: 'money'
}