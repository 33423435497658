import React, { useContext, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../utilities/appContext';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  root: {
    width: 265,
  },
  input: {
    width: 265,
    margin: "40px auto 0",
    fontSize: "18px",
    boxShadow: '3px 6px 5px 0px #dbdbdb',
    background: "#fff",
    '& input': {
      fontSize: "18px",
      padding: "12px",
    },
  },
  listItem: {
    fontSize: "18px",
  }
});



const CostOfLiving = () => {
  const classes = useStyles();
  const { costOfLiving, setCostOfLiving } = useContext(AppContext);

  useEffect(() => {
    sessionStorage.setItem('costOfLiving', costOfLiving)
  }, [costOfLiving])

  const handleChange = (event) => {
    setCostOfLiving(event.target.value)
  }

  return (
    <div className="prequiz">
      <div><label>What’s your anticipated retirement lifestyle? </label></div>
        <FormControl variant="outlined" className={classes.root}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={costOfLiving}
          onChange={handleChange}
          className={classes.input}
        >
          <MenuItem className={classes.listItem} value={2}>I'll need less income</MenuItem>
          <MenuItem className={classes.listItem} value={1}>I'll need the same income</MenuItem>
          <MenuItem className={classes.listItem} value={3}>I'll need more income</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default CostOfLiving;