import React, { useContext } from 'react';
import { NavLink as Link } from 'react-router-dom';
import Testimonials from '../components/testimonials';
import { AppContext } from '../utilities/appContext';

const LandingPage = (props) => {
  const { referralSite } = props;
  const { referral } = useContext(AppContext)
  const gridDotsOne = () => {
    const n = 49;
    return (
      [...Array(n)].map((e, i) => (
        <svg key={i} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="8" height="8" rx="1" fill="#48E8F2"/>
        </svg>
    ))
    )
  }

  const gridDotsTwo = () => {
    const n = 64;
    return (
      [...Array(n)].map((e, i) => (
        <svg key={i} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="8" height="8" rx="1" fill="#48E8F2"/>
        </svg>
    ))
    )
  }

  return(
    <div className="landing-page">
      {referralSite &&
        <div className="landing-page__referral">
          <div>{referral.assocEmail}</div>
          <div>{referral.assocName}</div>
          <div>{referral.assocPhone}</div>
        </div>
      }
      <div className="landing-page__header">

        <img src="https://tranontmedia.s3-us-west-2.amazonaws.com/Logos/tranontlife_logo.png" alt="Tranont Life" />
        <div className="landing-page__header--actions">
          <a href="https://app.tranontlife.com/login" target="_blank" rel="noopener noreferrer">Log In</a>
          <a href={referral.id !== '' ? `https://app.tranontlife.com/sign-up?ref_id=${referral.distId}` : "https://app.tranontlife.com/sign-up"} target="_blank" rel="noopener noreferrer">Sign Up</a>
        </div>
      </div>
      <div className="landing-page__hero">
        <h1>Live life with confidence.</h1>
        <h4>Learn how to manage, protect, and grow your finances.</h4>
        <a href='https://calendly.com/wealth-specialists/blue-number' className="header-btn" target="_blank" rel="noopener noreferrer">Schedule Now</a>
      </div>
      <div className="lp-section-one">
        <div className="lp-section-one__heading">
          <h2>Three simple steps to create your financial plan.</h2>
        </div>
          <div className="lp-section-one__content">
            <h4>Get Your Blue Number</h4>
            <ol>
              <li>Schedule Your Complimentary Analysis</li>
              <li>Get Your Blue Number</li>
              <li>Access Your Personalized Dashboard</li>
            </ol>
          </div>
        <svg className="lp-section-one__layer-one" viewBox="0 0 644 912" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="496.393" width="586.821" height="702.006" rx="146" transform="rotate(45 496.393 0)" fill="#DFF9F8"/>
        </svg>
        <svg className="lp-section-one__layer-two" viewBox="0 0 204 336" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="251.969" width="63.6173" height="118.633" rx="10" transform="rotate(-45 0 251.969)" fill="#88FAF3"/>
          <rect x="28.4004" y="148.591" width="63.6173" height="176.469" rx="10" transform="rotate(-45 28.4004 148.591)" fill="#48E8F2"/>
          <rect x="65.0928" y="44.9843" width="63.6173" height="230.613" rx="10" transform="rotate(-45 65.0928 44.9843)" fill="#00CEDB"/>
        </svg>
        <div className="lp-section-one__layer-three">
          {gridDotsOne()}
        </div>
        <div className="lp-section-one__layer-four">
          <img src="https://getmyblue-assets.s3-us-west-2.amazonaws.com/landing-page-dial.png" alt="Blue Number" />
        </div>

      </div>
      <div className="lp-section-two">
        <svg className="lp-section-two__layer-one" viewBox="0 0 509 912" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="-403" y="414.945" width="586.821" height="702.006" rx="146" transform="rotate(-45 -403 414.945)" fill="#DFF9F8"/>
        </svg>
        <div className="lp-section-two__layer-two">
          <div className="lp-section-two__content">
            <img src="https://getmyblue-assets.s3-us-west-2.amazonaws.com/landing-page-dashboard.png" alt="dashboard" />
            <div className="lp-section-two__content--text">
              <h4>View Your Personalized Dashboard</h4>
              <ul>
                <li>Your results securely stored for you to view</li>
                <li>Tools to track your progress</li>
                <li>Access to review your goals</li>
              </ul>
            </div>
          </div>
          <div className="lp-section-two__content">
            <div className="lp-section-two__content--text">
              <h4>Schedule Your Complimentary Analysis</h4>
              <ul>
                <li>Meet one-on-one with a specialist</li>
                <li>Develop a personalized plan tailored to your goals</li>
                <li>Get your financial questions answered, free with no obligation</li>
              </ul>
            </div>
            <img src="https://getmyblue-assets.s3-us-west-2.amazonaws.com/analysis-meeting.jpg" alt="dashboard" className="right-img" />
          </div>
        </div>
      </div>
      <h2 className="testimonials-header">The Blue Number Gets Results</h2>
      <div className="lp-section-three">
        <div className="lp-section-three__layer-one">
          <svg width="812" viewBox="0 0 912 912" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="414.945" width="586.821" height="702.006" rx="146" transform="rotate(-45 0 414.945)" fill="#DFF9F8"/>
          </svg>
        </div>
        <div className="lp-section-three__layer-two">
          {gridDotsTwo()}
        </div>
        <div className="lp-section-three__layer-three">
          <Testimonials />
        </div>
      </div>
      <div className="lp-final-cta">
        <h1>Start living your life with confidence today.</h1>
        <Link to="/get-started" className="cta-btn center-btn">Get My Blue Number</Link>
      </div>
      <div className="lp-final-cta last">
        <a href="https://calendly.com/wealth-specialists/blue-number" className="header-btn" target="_blank" rel="noopener noreferrer">Schedule Now</a>
      </div>
    </div>
  )
};

LandingPage.defaultProps = {
  referral: null
};

export default LandingPage;