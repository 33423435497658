import React from 'react';

export const Tag = () => (
  <svg className="icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75583 0.910915C8.6 0.754248 8.3875 0.666748 8.16666 0.666748H4C3.77916 0.666748 3.56666 0.754248 3.41083 0.910915L0.910831 3.41092C0.754164 3.56675 0.666664 3.77841 0.666664 4.00008V8.16675C0.666664 8.38841 0.754164 8.60008 0.910831 8.75591L9.24416 17.0892C9.40666 17.2517 9.62 17.3334 9.83333 17.3334C10.0467 17.3334 10.26 17.2517 10.4225 17.0892L17.0892 10.4226C17.415 10.0967 17.415 9.57008 17.0892 9.24425L8.75583 0.910915ZM9.83333 15.3217L2.33333 7.82175V4.34508L4.345 2.33341H7.82166L15.3217 9.83342L9.83333 15.3217Z" fill="#0665BD"/>
    <path d="M5.96083 7.33338C6.71884 7.33338 7.33332 6.71889 7.33332 5.96088C7.33332 5.20287 6.71884 4.58838 5.96083 4.58838C5.20281 4.58838 4.58833 5.20287 4.58833 5.96088C4.58833 6.71889 5.20281 7.33338 5.96083 7.33338Z" fill="#0665BD"/>
  </svg>
)

export const Bill = () => (
  <svg className="icon" viewBox="0 0 18 14" fill="none" style={{marginTop: "2px"}} xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 0.333252H1.50001C1.03917 0.333252 0.666672 0.705752 0.666672 1.16659V12.8333C0.666672 13.2941 1.03917 13.6666 1.50001 13.6666H16.5C16.9608 13.6666 17.3333 13.2941 17.3333 12.8333V1.16659C17.3333 0.705752 16.9608 0.333252 16.5 0.333252ZM15.6667 9.49992C14.2858 9.49992 13.1667 10.6191 13.1667 11.9999H4.83334C4.83334 10.6191 3.71417 9.49992 2.33334 9.49992V4.49992C3.71417 4.49992 4.83334 3.38075 4.83334 1.99992H13.1667C13.1667 3.38075 14.2858 4.49992 15.6667 4.49992V9.49992Z" fill="#00b8c3"/>
    <path d="M9.00001 3.66675C7.16167 3.66675 5.66667 5.16175 5.66667 7.00008C5.66667 8.83842 7.16167 10.3334 9.00001 10.3334C10.8383 10.3334 12.3333 8.83842 12.3333 7.00008C12.3333 5.16175 10.8383 3.66675 9.00001 3.66675ZM9.00001 8.66675C8.08084 8.66675 7.33334 7.91925 7.33334 7.00008C7.33334 6.08091 8.08084 5.33341 9.00001 5.33341C9.91917 5.33341 10.6667 6.08091 10.6667 7.00008C10.6667 7.91925 9.91917 8.66675 9.00001 8.66675Z" fill="#00b8c3"/>
  </svg>
)

export const Coin = () => (
  <svg className="icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.833252C4.94551 0.833252 0.833344 4.94542 0.833344 9.99992C0.833344 15.0544 4.94551 19.1666 10 19.1666C15.0545 19.1666 19.1667 15.0544 19.1667 9.99992C19.1667 4.94542 15.0545 0.833252 10 0.833252ZM10 17.3333C5.95659 17.3333 2.66668 14.0433 2.66668 9.99992C2.66668 5.9565 5.95659 2.66659 10 2.66659C14.0434 2.66659 17.3333 5.9565 17.3333 9.99992C17.3333 14.0433 14.0434 17.3333 10 17.3333Z" fill="#00b8c3"/>
    <path d="M10 9.08332C8.16668 9.08332 8.16668 8.50949 8.16668 8.16665C8.16668 7.72299 8.80926 7.24999 10 7.24999C11.0863 7.24999 11.2705 7.83482 11.2833 8.18315L12.2 8.16665H13.1167C13.1167 7.22615 12.5062 5.9034 10.9167 5.52757V4.51099H9.08334V5.49457C7.27659 5.79799 6.33334 6.98599 6.33334 8.16665C6.33334 9.19332 6.81001 10.9167 10 10.9167C11.8333 10.9167 11.8333 11.5363 11.8333 11.8333C11.8333 12.2137 11.265 12.75 10 12.75C8.31243 12.75 8.17676 11.9644 8.16668 11.8333H6.33334C6.33334 12.6748 6.93926 14.1736 9.08334 14.51V15.5H10.9167V14.5054C12.7234 14.202 13.6667 13.014 13.6667 11.8333C13.6667 10.8067 13.19 9.08332 10 9.08332Z" fill="#00b8c3"/>
  </svg>
)

export const Graph = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0V17C0 17.553 0.447 18 1 18H18V16H2V0H0Z" fill="#0665BD"/>
    <path d="M12.293 11.707C12.684 12.098 13.316 12.098 13.707 11.707L18.707 6.70697L17.293 5.29297L13 9.58597L10.707 7.29297C10.316 6.90197 9.684 6.90197 9.293 7.29297L4.293 12.293L5.707 13.707L10 9.41397L12.293 11.707Z" fill="#0665BD"/>
  </svg>
)

export const TranontBall = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill={props.color} d="M26.41,23.41a15.07,15.07,0,0,1-9.82,5.86S15,27.94,14.83,27.84s-.11-.18.17-.23c1.3-.26,6.35-1.45,8.8-4.8a53.22,53.22,0,0,0-8.08-6.66c-.58-.41-1.2-.84-1.84-1.27a24.68,24.68,0,0,0-2,2.39c-.05.14-1-1-1.1-1.13A20.93,20.93,0,0,1,12.53,14C11,13,9.42,12.05,8.12,11.28a16.16,16.16,0,0,0,3,7.74c3.18,4.25,8.8,5.64,8.8,5.64l-2.46,1.39a18.36,18.36,0,0,1-8-6,20.66,20.66,0,0,1-3.15-8.4,33.13,33.13,0,0,0-5.2,6.51,14.33,14.33,0,0,1-.19-3.94s.65-1.63,4.3-4.64L5,9.46A4.94,4.94,0,0,1,6.13,7.58l.94.55c.63-.45,1.25-.87,1.84-1.26l.7-.44C15.26,2.58,22.39,3,22.42,3a12.77,12.77,0,0,1,2.77,2.17,50.91,50.91,0,0,0-8,.59,22.51,22.51,0,0,0-7.19,3h0l-.78.55c1.79,1,3.64,2.11,5.07,3.06a15.59,15.59,0,0,1,8-3.19s-1.62-2.31-3-2.79a9.47,9.47,0,0,1,1.61-.79A21.05,21.05,0,0,1,24,9.2a15.09,15.09,0,0,1,2.2,5.22s-1.07.67-1.33.81a9.36,9.36,0,0,0-1.69-4.88c-2.77.59-5.15,1.13-7.55,3,.78.52,1.25.87,1.25.87,7.29,5.27,8.28,6.75,8.28,6.75,2.16-3,1.91-8.33,1.84-9.72,0-.3-.06-.48.21-.28S29,12.31,29,12.31A14.74,14.74,0,0,1,26.41,23.41Z"/>
    <path fill={props.color} d="M6.25,24.71A6.88,6.88,0,0,0,10.39,27a14,14,0,0,1-1,1,16.06,16.06,0,0,1-4.66-3.11,15.89,15.89,0,0,1-3-4.38,10.28,10.28,0,0,1,1.1-1.15,8.79,8.79,0,0,0,2.48,4.39c.54-1.13,2.38-3.83,2.38-3.83a3.56,3.56,0,0,1,1,1.24,25.44,25.44,0,0,0-2.36,3.55Z"/>
    <path fill={props.color} d="M7.15,3.74A15.25,15.25,0,0,0,2.93,8.69a4.52,4.52,0,0,1-.81-1,13.48,13.48,0,0,1,4.64-5,15.67,15.67,0,0,1,5-1.95,5.82,5.82,0,0,1,.62,1A10.13,10.13,0,0,0,8.32,3,5.19,5.19,0,0,1,9.66,4.51a3,3,0,0,1-1.33.67A3.14,3.14,0,0,0,7.15,3.74Z"/>
  </svg>
)

export const Deficit = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#E97442" d="M29.05,8.71l-.19,6.79v0a1066487901303.23,1066487901303.23,0,0,1,0,.12.19.19,0,0,1,0,.07v.06s0,0,0,0,0,0,0,.06l0,0,0,.05,0,0,0,0,0,.05s0,0,0,0h0l0,0,0,0,0,0-.05,0-.07,0h0l-.06,0-.1,0h-.06l-.17,0h-.29L20.7,14.15a1,1,0,0,1,.59-1.81l4.56,1.47c-6-8.49-20.37-9.39-20.53-9.4a1,1,0,0,1,.1-1.9c.67,0,15,.92,21.63,9.71l.1-3.56a.94.94,0,0,1,1-.92h0A1,1,0,0,1,29.05,8.71Z"/>
    <path fill="#E97442" d="M28.08,25.31H1.92a1.09,1.09,0,1,0,0,2.18H28.08a1.09,1.09,0,1,0,0-2.18Z"/>
    <path fill="#E97442" d="M13.18,24.49a1.36,1.36,0,0,0,1.36-1.36V13.51a1.35,1.35,0,0,0-1.35-1.36h-2.6a1.36,1.36,0,0,0-1.36,1.36v9.62a1.37,1.37,0,0,0,1.36,1.36Z"/>
    <path fill="#E97442" d="M3,10V23.13a1.36,1.36,0,0,0,1.36,1.36H7a1.36,1.36,0,0,0,1.36-1.36V10A1.36,1.36,0,0,0,7,8.61H4.37A1.36,1.36,0,0,0,3,10Z"/>
    <polygon fill="#E97442" points="29.05 18.76 29.05 18.76 29.05 18.76 29.05 18.76"/>
    <path fill="#E97442" d="M21.68,23.13A1.36,1.36,0,0,0,23,24.49h2.59A1.36,1.36,0,0,0,27,23.13V19.47a1.36,1.36,0,0,0-1.36-1.36H23a1.36,1.36,0,0,0-1.36,1.36Z"/>
    <path fill="#E97442" d="M15.46,23.13a1.36,1.36,0,0,0,1.36,1.36h2.59a1.36,1.36,0,0,0,1.36-1.36V17.46a1.36,1.36,0,0,0-1.36-1.36h-2.6a1.35,1.35,0,0,0-1.35,1.36Z"/>
  </svg>
)

export const Surplus = () => (

  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path fill="#00b8c3" d="M27.75,8.86a1,1,0,0,1-.93,1h0a1,1,0,0,1-1-.93l-.09-3.59c-6.69,8.85-21.13,9.75-21.8,9.78h0a1,1,0,0,1-.05-1.92c.16,0,14.67-.91,20.68-9.47L19.93,5.2a1,1,0,0,1-.29,0,.94.94,0,0,1-.91-.66,1,1,0,0,1,.61-1.21l7-2.24h0l.07,0h.43l.07,0,.07,0h0l.06,0,.07.05,0,0,0,0s.05,0,.06.06l0,0s0,0,0,0l.05.07a.05.05,0,0,1,0,0l0,.05,0,.07s0,0,0,.05h0s0,0,0,0,0,.05,0,.08a.09.09,0,0,1,0,0,.15.15,0,0,1,0,.07V2Zm1.3,19A1.08,1.08,0,0,0,28,26.76H2a1.08,1.08,0,1,0,0,2.16H28A1.08,1.08,0,0,0,29.05,27.84ZM8.38,20.78A.82.82,0,0,0,7.56,20H3.94a.82.82,0,0,0-.82.82V25a.83.83,0,0,0,.82.83H7.56A.83.83,0,0,0,8.38,25Zm18.5-8.49a.82.82,0,0,0-.82-.82H22.44a.82.82,0,0,0-.82.82V25a.83.83,0,0,0,.82.83h3.62a.83.83,0,0,0,.82-.83Zm-12.33,7a.83.83,0,0,0-.83-.83H10.11a.83.83,0,0,0-.83.83V25a.84.84,0,0,0,.83.83h3.61a.83.83,0,0,0,.83-.83Zm6.17-2.48a.83.83,0,0,0-.83-.83H16.28a.83.83,0,0,0-.83.83V25a.83.83,0,0,0,.83.83h3.61a.84.84,0,0,0,.83-.83Z"/>
  </svg>
)

export const Question = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={props.active ? "#0665BD" : "#C4C4C4"} d="M9.99998 4C7.83098 4 6.06598 5.765 6.06598 7.934H8.06598C8.06598 6.867 8.93398 6 9.99998 6C11.066 6 11.934 6.867 11.934 7.934C11.934 8.532 11.453 8.966 10.718 9.56C10.463 9.767 10.222 9.964 10.027 10.159C9.02898 11.156 8.99998 12.215 8.99998 12.333V13H11L10.999 12.367C11 12.351 11.032 11.981 11.44 11.574C11.59 11.424 11.779 11.274 11.975 11.116C12.754 10.485 13.933 9.532 13.933 7.934C13.934 5.765 12.169 4 9.99998 4ZM8.99998 14H11V16H8.99998V14Z" />
    <path fill={props.active ? "#0665BD" : "#C4C4C4"} d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18Z"/>
  </svg>
)