import { useContext } from 'react';
import {AppContext} from '../utilities/appContext';

const CalculateSocialSecurity = () => {
  const { income, retirementAge, age, setSocialSecurity } = useContext(AppContext)
  const firstBendPoint = 960
  const secondBendPoint = 5785
  const yearsUntilRetirement = Number(retirementAge) - Number(age)
  let adult
  if (Number(age) === 18) {
    adult = 17
  } else { 
    adult = 18
  }
  const yearsSinceEighteen = Number(age) - adult;
  const wage = Number(income)
  let ageAtRetirement = Number(retirementAge)
  let arr = []
  let aime 
  let pia 
  let adjustment
  let benefit
  let rate = 0


  for(let i=1;i<=yearsSinceEighteen;i++){
    arr.push(wage - (wage * (i * .015)))
  }
  for(let i=0;i<yearsUntilRetirement;i++) {
    let prevYear = arr[arr.length -1]
    arr.push(prevYear + (prevYear * .015))
  }
  const allEarnings = arr.sort((a, b) => { return b - a })
  const indexedEarnings =  allEarnings.slice(0,35);

  let total = 0 
  for(let i = 0; i < 35; i++) {
    total += indexedEarnings[i];
  }
  aime = (total / 35) / 12;

  if (aime > secondBendPoint) {
    pia = ((firstBendPoint * .9) + ((secondBendPoint - firstBendPoint) * .32) + ((aime - secondBendPoint) * .15))
  } else if (aime > firstBendPoint) {
    pia = ((firstBendPoint * .9) + ((aime - firstBendPoint) * .32))
  } else {
    pia = (aime * .9)
  };

  if (ageAtRetirement < 62 ) {
    adjustment = 0
  } else if (67 > ageAtRetirement && ageAtRetirement >= 62) {
    adjustment = (pia * .75)
  } else if (ageAtRetirement === 67) {
    adjustment = pia
  } else if (ageAtRetirement === 68) {
    adjustment = (pia * 1.08)
  } else if (ageAtRetirement === 69) {
    adjustment = (pia * 1.16)
  } else if (ageAtRetirement >= 70 ) {
    adjustment = (pia * 1.24)
  }

  if (ageAtRetirement > 69) {
    if (adjustment > 3790) {
      benefit = 3790
    } else {
      benefit = adjustment
    } 
  } else if (ageAtRetirement > 66 && ageAtRetirement   <= 69) {
    if (adjustment > 3011) {
      benefit = 3011
    } else {
      benefit = adjustment
    }
  } else if (ageAtRetirement >= 62 && ageAtRetirement <= 66) {
    if (adjustment > 2265) {
      benefit = 2265
    } else {
      benefit = adjustment
    }
  } else {
    benefit = 0
  }

  const futureValueOfBenefit = benefit * (1 + rate/1)**(yearsUntilRetirement)

  let rounded = Math.floor(futureValueOfBenefit)
  benefit = rounded
  setSocialSecurity(benefit)

  return(null)
}

const CalculateFutureValue = () => {
  const { savings, contribution, age, retirementAge, setFutureValue, investmentStyle } = useContext(AppContext);
  const pv = Number(savings)
  const r = Number(investmentStyle) / 100
  const t = (Number(retirementAge) - Number(age))
  const pmt = Number(contribution) * 12
  const n = 1

  let accountFutureValue = pv * (1 + r/n)**(t)
  let contributionFutureValue  = pmt*(((1 + r/n)**(t)-1)/(r/n))

  let total = accountFutureValue + contributionFutureValue
  total = Math.round(total)
  setFutureValue(total)

  return(null)
}

const CalculateMaxWithdrawl = () => {
  const { futureValue, lifeExpectancy, retirementAge, setMaxWithdrawl} = useContext(AppContext);

  var pv = futureValue
  var r = .0035
  var n = lifeExpectancy - retirementAge

  let estimate = ((pv * (r/(1 - (1 + r)**-n)) * 1/(1 + r)) / 12)
  let rounded = Math.round(estimate)
  let monthlyWithdrawal = rounded;
    
  setMaxWithdrawl(monthlyWithdrawal)

  return(null)
}

const CalculateWhatYoullHave = () => {
  CalculateMaxWithdrawl();
  const { socialSecurity, maxWithdrawl, setWhatYoullHave } = useContext(AppContext);
  const a = Number(socialSecurity)
  const b = Number(maxWithdrawl)

  setWhatYoullHave(a+b)

  return(null)
}

const CalculateWhatYoullNeed = () => {
  const {income, standardOfLiving, age, retirementAge, setWhatYoullNeed, costOfLiving} = useContext(AppContext)

  let pv = Number(income)
  
  let rate = 0

  const n = 1
  const t = (Number(retirementAge) - Number(age))

  let col
  if (Number(costOfLiving) === 1 ) {
    // same
    col = 15/100 
  } else if (Number(costOfLiving) === 2 ) {
    col = -10/100 //less
  } else if (Number(costOfLiving) === 3 ) {
    col = 30/100 //more
  }


  let fv

  fv = pv * (1 + rate/n)**(t)
  let rounded = (Math.round(fv)/12)
  let updatedNeeds = rounded + (rounded * col)
  let needs 
  if (standardOfLiving !== 0 && standardOfLiving !== '') {
    needs = Number(standardOfLiving)
  } else {
    needs = Math.round(updatedNeeds)
  }
  setWhatYoullNeed(needs)
  
  return(null)
}

const CalculateBlueNumber = () => {
  const {whatYoullHave, whatYoullNeed, setBlueNumber, maxWithdrawl} = useContext(AppContext)

  let calculateBlueNumber = Number(whatYoullHave) / Number(whatYoullNeed)
  let bluenumber = Math.round(calculateBlueNumber * 100)

  if (Number(maxWithdrawl) === 0) {
    if (bluenumber > 100) {
      setBlueNumber(Math.round(100 * .45))
    } else {
      setBlueNumber(Math.round(bluenumber * .67))
    }
  } else {
    if (bluenumber > 100) {
      setBlueNumber(100)
    } else if (bluenumber < 0) {
      setBlueNumber(0)
    } else {
      setBlueNumber(bluenumber)
    }
  }

  return(null)
}

export const CalculateResults = () => {
  CalculateSocialSecurity();
  CalculateFutureValue();
  CalculateMaxWithdrawl();
  CalculateWhatYoullHave();
  CalculateWhatYoullNeed();
  CalculateBlueNumber()
  return(null)
}