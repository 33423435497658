import React from 'react'

const Widget = (props) => {
  const { children, color, percent, attention } = props;
  
  return (
  <div className={attention ? "results-widgets__container red-border" : "results-widgets__container"}>
    {attention && <a href="#alert"><div className="attention">!</div></a> }
    <svg viewBox="0 0 36 36" className={`svg-meter ${color}`}>
      <path className="circle-bg"
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      <path className="circle"
        strokeDasharray={`${percent}, 100`}
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
        />
    </svg>
    { children }
  </div>
  )
}

export default Widget;

Widget.defaultProps = {
  attention: false,
}