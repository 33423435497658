export const blackListUrls = [
  "affiliate",
  "blackdiamond",
  "bluediamond",
  "health",
  "wealth",
  "community",
  "life",
  "vibe",
  "balance",
  "restore",
  "icaria",
  "events",
  "packs",
  "convention",
  "tranont",
  "brizopure",
  "suthe",
  "serene",
  "releaf",
  "blog",
  "home-services",
  "retail-partners",
  "business-services",
  "health-advisory",
  "about",
  "blue",
  "tranontlife",
  "contact",
  "error",
  "cart",
  "jeep4u",
  "brizopurejobs",
  "businessopportunity",
  "buy",
  "buycbd",
  "buyenzymes",
  "careers",
  "cbd",
  "changelife",
  "company",
  "convention",
  "cure",
  "dc",
  "diamond",
  "directsales",
  "distimages",
  "distimageservice",
  "emerald",
  "feelwhole",
  "feelwholeagain",
  "files",
  "free",
  "freecbd",
  "freeenzymes",
  "freejeep",
  "freeshipping",
  "heal",
  "healthwealthcommunity",
  "healthy",
  "icaria",
  "icariaglow",
  "imageservice",
  "jade",
  "jeep",
  "jeepforyou",
  "jobs",
  "marketing",
  "mlm",
  "networkmarketing",
  "oi",
  "onebillion",
  "opportunity",
  "pearl",
  "pyramid",
  "glow",
  "loading",
  "terms-of-use",
  "return-policy",
  "privacy-policy",
  "techno-boost",
  "techno",
  "boost",
  "india",
  "get-started",
  "results"
]