import React, { useContext } from 'react';
import { AppContext } from '../utilities/appContext';
import { Input } from './input';

export const DateOfBirth = () => {
  const { age, setAge } = useContext(AppContext);
  
  return (
    <Input
      label="What is your age?"
      value={age}
      handleChange={setAge}
      type="number"
      placeholder="Enter your age"
      name="age"
      message="We use your age to calculate how many years you have until retirement."
      number="number"
    />
  )
};

export const Income = () => {
  const { income, setIncome } = useContext(AppContext);

  return (
    <Input
      label="What is your current annual income?"
      value={income}
      handleChange={setIncome}
      type="number"
      placeholder="$"
      name="income"
      message="We use annual income to determine how much income you may need for retirement. Enter your estimated gross income (pre-tax)."
    />
  )
};

export const Savings = () => {
  const { savings, setSavings } = useContext(AppContext);

  return (
    <Input
      label="How much have you saved for retirement?"
      value={savings}
      handleChange={setSavings}
      type="number"
      placeholder="$"
      name="savings"
      message="We will estimate the growth of current savings through your retirement. This includes any retirement accounts you may have: 401(k), IRAs, pensions, etc."
    />
  )
};

export const Contribution = () => {
  const { contribution, setContribution } = useContext(AppContext);

  return (
    <Input
      label="How much are you contributing monthly to your retirement account?"
      value={contribution}
      handleChange={setContribution}
      type="number"
      placeholder="$"
      name="contribution"
      message="We will estimate the growth of your monthly retirement contribution. This does not include a savings account or emergency fund. "
    />
  )
};