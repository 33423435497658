import React, { useContext } from 'react'
import { AppContext } from '../utilities/appContext';
import isEmail from 'validator/lib/isEmail';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { QuestionsContext } from '../utilities/questionsContext';
import { QuizBackground } from './quizBackground';

const ADD_LIFE_SUBSCRIBER = gql`
  mutation addLifeSubscriber($subscriber: SubscriberInputType!) {
    addLifeSubscriber(subscriber: $subscriber) {
      success
    }
  }
`

const Subscribe = () => {
  const {email, setEmail, blueNumber, age, referral } = useContext(AppContext);
  const {step, setStep} = useContext(QuestionsContext)

  let history = useHistory();

  const handleNavigation = () => {
    history.push("/results")
  }

  return (
    <div>
      <div className="quiz">
      <QuizBackground />
      <div className="quiz__content">
        <p className="opacity-hidden">Get your <span className="bold">Blue Number</span> in less than 1 minute by answering 5 quick questions.</p>
        <div className="form-container">
          <p className="step">You're almost finished!</p>
          <form className="prequiz">
            <div><label>Enter your email to view your results.</label></div>
            <input
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
            />
            <div style={{margin: "1rem"}}><span className="text-small"> We're committed to your privacy and confidentiality.</span></div>
          </form>
        </div>
        {isEmail(email) ? 
        <Mutation 
          mutation={ADD_LIFE_SUBSCRIBER}
          variables={{"subscriber": {"email": email, "blueNumber": Number(blueNumber), "age": Number(age), "referral": Number(referral.distId) }}}
          >
          {subscribe => 
            <div className="form-controls">{step > 1 ? <div onClick={() => setStep(step - 1)} className="back-btn">{ "< " } Back</div> : <div></div>}<button onClick={() => {subscribe(); handleNavigation()}} className="next-btn">Get my results!</button></div>
          }
        </Mutation>
        :
        <div className="form-controls">{step > 1 ? <div onClick={() => setStep(step - 1)} className="back-btn">{ "< " } Back</div> : <div></div>}<button className="next-btn disabled">Get My Results!</button></div>
        }
      </div>
      </div>
      <div className="skip">
        <Link to="/results">Skip, and continue as a guest</Link>
      </div>
    </div>
  )

}

export default Subscribe;