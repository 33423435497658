import React, {useState, useContext, useEffect} from 'react';
import ScoreDial from './scoreDial';
import ResultsInputContainer from './resultsInputContainer';
import { CalculateResults } from './calculateResults';
import ResultsWidgets from './resultsWidgets';
import ResultsDescription from './resultsDescription';
import { AppContext } from '../utilities/appContext';
import isEmail from 'validator/lib/isEmail';
import ResultsAlerts from './resultsAlerts';

const Results = () => {
  const isMobile = window.innerWidth < 989
  const { referral, seenResults, setSeenResults, email, age, income, savings, contribution, lifeExpectancy, retirementAge } = useContext(AppContext)
  const [modifier, setModifier] = useState(false);
  const [ctaLink, setCtaLink] = useState('')

  useEffect(() => {
    handleView();
  })
  
  useEffect(() => {
    linkBuilder();
  })

  const modifierHandler = () => {
    if (modifier) {
      setModifier(false)
    } else {
      setModifier(true)
    }
  }

  const handleView = () => {
    if (!seenResults) {
      setSeenResults(true)
      sessionStorage.setItem('seenResults', true)
    }
  }

  const linkBuilder = () => {
    console.log(email)
    if (referral.distId !== '' && isEmail(email)) {
      setCtaLink(`https://app.tranontlife.com/sign-up?ref_id=${referral.distId}&email=${email}&cbn="jbwh"&age=${age}&income=${income}&savings=${savings}&contribution=${contribution}&retirement=${retirementAge}&life_expectancy=${lifeExpectancy}`)
    } else if ( referral.distId !== '' && !isEmail(email)) {
      setCtaLink(`https://app.tranontlife.com/sign-up?ref_id=${referral.distId}&cbn="jbwh"&age=${age}&income=${income}&savings=${savings}&contribution=${contribution}&retirement=${retirementAge}&life_expectancy=${lifeExpectancy}`)
    } else if ( referral.distId === '' && isEmail(email)) {
      setCtaLink(`https://app.tranontlife.com/sign-up?email=${email}&cbn="jbwh"&age=${age}&income=${income}&savings=${savings}&contribution=${contribution}&retirement=${retirementAge}&life_expectancy=${lifeExpectancy}`)
    } else {
      setCtaLink(`https://app.tranontlife.com/sign-up?&cbn="jbwh"&age=${age}&income=${income}&savings=${savings}&contribution=${contribution}&retirement=${retirementAge}&life_expectancy=${lifeExpectancy}`)
    }
  }

  return (
    <div className="results-page">
      <div className="results-header">
        <img src="https://tranontmedia.s3-us-west-2.amazonaws.com/Logos/tranontlife_logo.png" alt="Tranont Life" />
        <div className="results-header__cta">
          <p>Don't miss out on your retirement dreams!</p>
          <a href={ctaLink} target="_blank" rel="noopener noreferrer" className="results-header__cta--button">Create Account</a>
        </div>
      </div>
      <div className="results">
        {isMobile ? 
          <>
            <div onClick={() => modifierHandler()} className="modify-btn">
              {modifier ?
                <div>Close</div>
                :
                <div>My Details</div>
              }
            </div>
            {modifier ?
              <div className="results__inputs">
                <ResultsInputContainer />
                <ResultsAlerts />
              </div>
              :
              <div>
                <ResultsAlerts />
              </div>
            }
          </>
            :
            <div className="results__inputs">
              <ResultsInputContainer />
            </div>
        }

        <CalculateResults />
        <div className="results__outcomes">
          <p className="blue-label">Blue Number</p>
          <p className="results-title">Retirement Calculator</p>
          <ScoreDial />
          <ResultsWidgets />
          <ResultsDescription />
          {!isMobile && 
            <ResultsAlerts />
          }
        </div>
      </div>
      <div className="results-cta">
        <div className="results-cta__hero">
          <h4>You have your Blue Number, now take the next step and access your dashboard.</h4>
          <div className="results-cta__hero--button">
            <a href={ctaLink} className="cta-btn center-btn">Create Account</a>
          </div>
        </div>
        <div className="results-cta__content">
          <h2>How to start improving your Blue Number.</h2>
          <div className="results-cta__content--grid">
            <h4>Focus on starting today</h4>
            <p>We all know the principle that it’s better to start earlier – but don’t worry if you’re starting later in the game. The idea is to start today. We have strategies for you to start today, to develop a plan to help you reach your goals. </p>
          </div>
          <div  className="results-cta__content--grid">
            <h4>Set goals</h4>
            <p>You may find it easier to start with simple goals like making a cup of coffee at home versus spending $6 in a drive-thru. By assessing your expenses, you can better determine where you can save money, adding to your overall savings. There’s a psychology behind the small victories that add to your overall goal.</p>
          </div>
          <div className="results-cta__content--grid">
            <h4>Increase your monthly savings</h4>
            <p>Increasing your savings play a key role in your end goal. By evaluating your needs and wants, you might be able to find extra cash to put into a savings plan.</p>
          </div>
          <div className="results-cta__content--grid">
            <h4>Fund a plan to make your money grow</h4>
            <p>It is key to make your money grow for you. There are many options for a savings plan such as a 401(k), Roth IRA, Indexed Universal Life Insurance, and so much more!</p>
          </div>
          <div className="results-cta__content--grid">
            <h4>Work with a specialist</h4>
            <p>We have a team of expert wealth specialists that can help you put together a plan to achieve your goals. They will answer financial questions and concerns, while assisting you in creating a personalized financial plan.</p>
          </div>
          <div className="results-cta__content--disclaimer">
            <h4>How is my Blue Number calculated?</h4>
            <p>Your Blue Number is determined by calculating the difference between your estimated retirement need and the estimated value of what you may have. Our formula also includes an annualized 2% inflation rate and a Social Security estimate based on your current age and income.  Simply put, your Blue Number score is the estimated achieved percentage of your retirement needs goal.</p>
          </div>
        </div>
        <div className="results-cta__hero-2">
          <h4>Don't miss out on building a plan you'll be proud of.</h4>
          <div className="results-cta__hero--button">
            <a href={ctaLink} className="cta-btn center-btn">Create Account</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Results;